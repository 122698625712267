import { captureRemixErrorBoundaryError } from "@sentry/remix"
import {
  isRouteErrorResponse,
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from "@remix-run/react"

import type { LinksFunction } from "@remix-run/node"
import stylesheet from "./global.css?url"
import Loading from "~/components/loading-animation"
import { Toaster } from "./components/ui/shadcn/toaster"
import { isAxiosError } from "axios"

export const links: LinksFunction = () => [{ rel: "stylesheet", href: stylesheet }]

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="ja">
      <head>
        <title>リーナーコネクト</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <Toaster />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export const ErrorBoundary = () => {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)
  if (isAxiosError(error)) {
    if (error.status === 401) {
      return (
        <ErrorLayout>
          <div className="space-y-4">
            <h1 className="text-2xl">セッションの有効期限が切れています</h1>
            <div>
              <p>
                認証情報の保有期間をすぎたか、なんらかの理由で認証情報が取得できませんでした。
                <br />
                <Link to="/login" className="text-primary">
                  ログインページ
                </Link>
                より再度ログインしてください。
              </p>
            </div>
          </div>
        </ErrorLayout>
      )
    }
  }

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <ErrorLayout>
          <div>
            <h1 className="text-2xl">お探しのページは見つかりませんでした</h1>
            <div>
              <p>
                <Link to="/login" className="text-primary">
                  ログインページ
                </Link>
              </p>
            </div>
          </div>
        </ErrorLayout>
      )
    }
  }

  return (
    <ErrorLayout>
      <div className="space-y-4">
        <h1 className="text-2xl">エラーが発生しました</h1>
        <div>
          <p>
            しばらくしてから再度お試しください。
            <br />
            問題が解消しない場合は、管理者にお問い合わせください。
          </p>
        </div>
      </div>
    </ErrorLayout>
  )
}

export function ErrorLayout({ children }: { children: React.ReactNode }) {
  return <div className="p-4">{children}</div>
}

export default function App() {
  return <Outlet />
}

export function HydrateFallback() {
  return (
    <div>
      <Loading />
    </div>
  )
}
